import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulText from '../../components/ContentfulText'
import MainLayout from '../../components/MainLayout'
import PressCard from '../../components/PressCard'
import DemoFooter from '../../components/DemoFooter'
import './styles.scss'

const SOCIAL_MEDIA = [
  {name:'linkedin', url:'https://www.linkedin.com/shareArticle?url='},
  {name:'facebook', url:'https://www.facebook.com/sharer/sharer.php?display=popup&u='},
  {name:'twitter', url:'http://twitter.com/share?hashtags=online,food,restaurants&url='},
]
const Stat = ({data}) => {
  return (
    <div className='stat'>
      <div className='stat-title'>
        {data.title}
      </div>
      <div className='stat-value'>
        {data.subtitle}
      </div>

    </div>
  )
}

Stat.propTypes = {
  data: PropTypes.object.isRequired,
}

const pressPage = (props) => {
  const { data, pressPages, footerData } = props.pageContext
  const imageUrl = get(data, 'image.file.url')
  const successStatsImageUrl = get(data, 'successStatsImage.file.url')
  const headerData = get(props, 'data.allContentfulHomeV3.edges[0].node')
  const summary = get(data, 'summary.summary')
  const dateText = get(data, 'dateInfo.dateInfo')
  return (
    <MainLayout data={headerData} headerClass='eg-press-header' >
      <div className='eg-press-article'>
        <div className='title'>
          {data.title}
        </div>
        {
          dateText &&
          <div className='dateInfo'>
            {dateText}
          </div>
        }
        {!!imageUrl && <img className='image' src={imageUrl} alt={data.title}/> }
        <div className='details'>
          <div className='left-part'>
            { summary && <div className='summary'>
              <ContentfulText data={summary} />  
            </div>
            }
            <div className='main-content'>
              <ContentfulText data={data.articleContents.articleContents} />
            </div>
          </div>
          <div className='right-part'>
            <img src={get(data,'sourceLogo.file.url')} align='left' className='source' alt='media' />
            <a href={data.sourceLink} className='link' target='_blank' rel="noopener noreferrer">
              {data.linkText ? data.linkText : `Read article on ${data.source}`}
            </a>
            {(data.successStats || []).map((stat, index) => <Stat data={stat} key={index}/>)}
            {successStatsImageUrl && <img className='graph' src={successStatsImageUrl} alt='graph'/>}
            <div className="share">
              <span>Share</span>
              {SOCIAL_MEDIA.map((social, index) => <a
                className={social.name}
                href={`${social.url}${encodeURIComponent(data.sourceLink)}`}
                target='_blank'
                rel="noopener noreferrer"
                key={index}>
                <div/>
              </a>)}
            </div>
          </div>
        </div>
        <div className="share-mobile">
          <span>Share</span>
          {SOCIAL_MEDIA.map((social, index) => <a
            className={social.name}
            href={`${social.url}${encodeURIComponent(data.sourceLink)}`}
            target='_blank'
            rel="noopener noreferrer"
            key={index}>
            <div/>
          </a>)}
        </div>
        <div className='zsf-etc-press'>
          <div className='title'>
            More ZeroStorefront News
          </div>
          <div className='press-items'>
            {pressPages.map((item, index) => <PressCard data={item} key={index}/>)}
          </div>
        </div>
      </div>
      <DemoFooter data={ footerData }/> 
    </MainLayout>
  )
}

export const pressPageQuery = graphql`
  query PressPage {
    allContentfulHomeV3 {
      edges {
        node {
          title
          hero {
            cta
          }
        }
      }
    }
  }
  `


pressPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default pressPage
