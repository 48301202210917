import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { EGAnalytics, analyticEvents } from '../../services/analytics'
import EmailBox from '../EmailBox'
// import GridIllustration from '../../GridIllustration'
import './styles.scss'

const GetDemo = props => {
  const { data, className = '' } = props
  const description = get(data, 'description.description')
  const imageUrl = get(data, 'image.file.url')
  const handleSubmit = () => {
    EGAnalytics.track(analyticEvents.CTA_INTERACTION, {
      page: 'Press Page',
      section: 'Get Demo',
      ctaLabel: data.cta
    })
  }

  return (
    <section className={ `eg-demo-section ${className}` } id='eg-demo-section'>
      <div className='inner'>
        <div className='left-part'>
          <div className='title'>{ data.title }</div>
          { data.subHeader && <div className='subtitle'>{ data.subHeader }</div> }
          <div className='description'>{ description }</div>
          <EmailBox cta= {data.cta} onSubmit={ handleSubmit } section='Get Demo' />
        </div>
        <div className='right-part'>
          <img src={imageUrl} className='source' alt='SMS previwe'/>
        </div> 
      </div>
    </section>
  )
}

GetDemo.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default GetDemo
